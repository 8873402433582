import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';

const options = {
  loop:true,
  autoplay: true,
  margin:0,
  animateOut: 'fadeOut',
  animateIn: 'fadeIn',
  nav:false,
  autoplayHoverPause: false,
  items: 1,
  clickSlide : false,
  touch: false,
  mouseDrag: false,
  touchDrag: true,
  navText : ["<span class='ion-md-arrow-back'></span>","<span class='ion-chevron-right'></span>"],
  responsive:{
    0:{
      items:1
    },
    600:{
      items:1
    },
    1000:{
      items:1
    }
  }
};

const events = {
  onDragged: function(event) {},
  onChanged: function(event) {}
};
export default class Carousel extends Component {
  render() {
    return (
      <section id="home-section" className="hero">
        <OwlCarousel className="home-slider owl-carousel" ref="car" options={options} events={events} >
          <div className="slider-item ">
            <div className="overlay" />
            <div className="container">
              <div className="row d-md-flex no-gutters slider-text align-items-end justify-content-end" data-scrollax-parent="true">
                <div className="one-third order-md-last img" style={{backgroundImage: 'url(images/bg_1.jpg)'}}>
                  <div className="overlay" />
                </div>
                <div className="one-forth d-flex  align-items-center ftco-animate" data-scrollax=" properties: { translateY: '70%' }">
                <a href="https://www.youtube.com/watch?v=Z98au9bLW1o" class="icon-video popup-vimeo d-flex justify-content-center align-items-center">
	    						<span class="ion-ios-play play"></span>
	    					</a>
                  <div className="text">
                    <span className="subheading">Hello</span>
                    <h1 className="mb-4 mt-3">I'm <span>Pradeep Sambandam</span></h1>
                    <h2 className="mb-4">Senior Software Engineer @ KaviSoftek</h2>
                    <p><a href="https://in.linkedin.com/in/pradeep-sambandam-17335643/" target="_blank" rel="noopener noreferrer" className="btn-custom">Hire me</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slider-item">
            <div className="overlay" />
            <div className="container">
              <div className="row d-flex no-gutters slider-text align-items-end justify-content-end" data-scrollax-parent="true">
                <div className="one-third order-md-last img" style={{backgroundImage: 'url(images/bg_2.jpg)'}}>
                  <div className="overlay" />
                </div>
                <div className="one-forth d-flex align-items-center ftco-animate" data-scrollax=" properties: { translateY: '70%' }">       
                <a href="https://www.youtube.com/watch?v=Z98au9bLW1o" class="icon-video popup-vimeo d-flex justify-content-center align-items-center">
	    						<span class="ion-ios-play play"></span>
	    					</a>           
                  <div className="text">
                    <h1 className="mb-4 mt-3">I'm a <span>Web Developer</span> from India</h1>
                    <p><a href="https://in.linkedin.com/in/pradeep-sambandam-17335643/" target="_blank" rel="noopener noreferrer" className="btn-custom">Hire me</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </section>
    )
  }
}
