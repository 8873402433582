import React, { Component } from 'react';
import './App.css';
import Nav from './components/nav'
import Carousel from './components/carousel'
import About from './components/about';
import Skills from './components/skills';
import Projects from './components/projects';
import Contact from './components/contact';
import Footer from './components/footer';
import Script from 'react-load-script';


class App extends Component {
  render() {
    return (
      <div>
        <Nav></Nav>
        <Carousel></Carousel>
        <About></About>
        <Skills></Skills>
        <Projects></Projects>
        <Contact></Contact>
        <Footer></Footer>
        <Script
          url="js/main.js"
        />
      </div>      
    );
  }
}

export default App;
