import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (
      <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center ftco-animate">
              <span className="subheading">Contact</span>
              <h2 className="mb-4">Contact Me</h2>
            </div>
          </div>
          <div className="row no-gutters block-9">
            <div className="col-md-6 order-md-last d-flex">
              <div className="bg-light p-4 p-md-5 contact-form">
                <h2 className="ftco-heading-2">Have a Questions?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li><span className="icon icon-map-marker" /><span className="text">Valappakudi Main Road, Sriram nagar, Thiruvaiyaru, Thanjavur - 613204. Tamilnadu, India.</span></li>
                    <li><a href="tel:+91 88706 19442"><span className="icon icon-phone" /><span className="text">+91 88706 19442</span></a></li>
                    <li><a href="mailto:a.sambandam.pradeep@gmail.com"><span className="icon icon-envelope" /><span className="text">a.sambandam.pradeep@gmail.com</span></a></li>
                  </ul>                  
                </div>
                <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                  <li className="ftco-animate"><a href="https://in.linkedin.com/in/pradeep-sambandam-17335643/" target="_blank" rel="noopener noreferrer"><span className="icon-linkedin" /></a></li>
                  <li className="ftco-animate"><a href="https://github.com/aspradeep/" target="_blank" rel="noopener noreferrer"><span className="icon-github" /></a></li>
                  <li className="ftco-animate"><a href="https://www.facebook.com/ASPradeep88/" target="_blank" rel="noopener noreferrer"><span className="icon-facebook" /></a></li>                  
                  <li className="ftco-animate"><a href="http://bit.ly/29WQTLt" target="_blank" rel="noopener noreferrer"><span className="icon-tasks" /></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="img" style={{backgroundImage: 'url(images/about.jpg)'}} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}
