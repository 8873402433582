import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <section className="ftco-about ftco-counter img ftco-section" id="about-section">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-6 col-lg-5 d-flex">
              <div className="img-about img d-flex align-items-stretch">
                <div className="overlay" />
                <div className="img d-flex align-self-stretch align-items-center" style={{backgroundImage: 'url(images/about-1.jpg)'}}>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-7 pl-lg-5 py-5">
              <div className="row justify-content-start pb-3">
                <div className="col-md-12 heading-section ftco-animate">
                  <span className="subheading">Welcome</span>
                  <h2 className="mb-4" style={{fontSize: '34px', textTransform: 'capitalize'}}>About Me</h2>
                  <p>An Engineering Graduate from India with more than 7 years of Web Development in WordPress, HTML, CSS, JS, ReactJS, jQuery. Starting my career as a Junior Web Developer, have experience in running my own freelancing agency (<a href="http://www.kavisoftek.in/" target="_blank" rel="noopener noreferrer">Kavisoftek</a>) for more than 5 Years where as a Senior Web Developer I have completed more than 300 successful projects.</p>
                  <p>I am passionate about learning new technologies & methodologies for best in class web development.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="media block-6 services d-block ftco-animate">
                    <div className="icon"><span className="flaticon-web-design" /></div>
                    <div className="media-body">
                      <h3 className="heading mb-3">UI Development</h3>
                      <p>Most of my projects include UI Development which involves conversion of a PSD design to a working Web Application with various web technologies.</p>
                    </div>
                  </div> 
                </div>
                <div className="col-md-6">
                  <div className="media block-6 services d-block ftco-animate">
                    <div className="icon"><span className="flaticon-analysis" /></div>
                    <div className="media-body">
                      <h3 className="heading mb-3">100% Success Rate</h3>
                      <p>Projects I worked had 100% Success Rate with a great Customer Satisfaction which gave me experience of handling variety of Clients.</p>
                    </div>
                  </div> 
                </div>
              </div>
              <div className="counter-wrap ftco-animate d-flex mt-md-3">
                <div className="text p-4 pr-5 bg-primary">
                  <p className="mb-0">
                    <span>More than</span>
                    <span className="number" data-number={300}>0</span>
                    <span>Finished Projects</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
